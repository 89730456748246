import React from "react"

import style from "./video.module.css"

const Video = ({ title, video }) => {
	if (!video) return null
	video = video.replace("watch?v=", "embed/")

	return (
		<div className={style.video}>
			<iframe
				title={title}
				src={video}
				frameborder="0"
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen
			></iframe>
		</div>
	)
}

export default Video
