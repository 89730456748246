import React from "react"
import { graphql } from "gatsby"
import Event from "../components/event"

export default ({ data }) => {
	return <Event event={data.markdownRemark} />
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark(frontmatter: { slug: { eq: $slug } }) {
			...Event
		}
	}
`
