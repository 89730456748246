import React from "react"
import { graphql } from "gatsby"

import Layout from "./layout"
import Link from "./link"
import EventTitle from "./eventTitle"
import Byline from "./byline"
import Video from "./video"
import SpeakerName from "./speakerName"
import SpeakerCard from "./speakerCard"
import RenderHtml from "./renderHtml"

import style from "./event.module.css"

const Event = ({ event, cancellationLink }) => {
	const socialImage = determineSocialImage(event.frontmatter)
	const canceled = event.frontmatter.canceled || cancellationLink
	return (
		<Layout
			meta={{
				title: event.frontmatter.title,
				description: event.frontmatter.excerpt,
				keywords: `${event.frontmatter.title} Karlsruhe`,
				path: event.fields.path,
				socialImage,
			}}
		>
			<div className={canceled ? style.canceled : ""}>
				<h1 className={style.title}>
					<EventTitle event={event} />
				</h1>
				<Byline
					track={event.frontmatter.track}
					format={event.fields.collection}
					slug={event.frontmatter.slug}
				/>
				<div className={style.description}>
					{canceled && showCancellation(cancellationLink)}
					<RenderHtml withAst={event} />
				</div>
			</div>
			{showEpilogue(
				event.frontmatter.title,
				event.frontmatter.slides,
				event.frontmatter.video
			)}
			{speakerList(event.frontmatter.speakers)}
		</Layout>
	)
}

function determineSocialImage(frontmatter) {
	if (frontmatter.card) return `card-${frontmatter.slug}.jpg`
	const speakerWithCard = frontmatter.speakers.find(speaker => speaker.frontmatter.card)
	if (speakerWithCard) return `card-${speakerWithCard.frontmatter.slug}.jpg`
}

function showCancellation(cancellationLink) {
	return (
		<div className={style.cancellation}>
			<p className={style.title}>canceled</p>
			{cancellationLink && (
				<p className={style.details}>
					See <Link to={cancellationLink}>here</Link> for details.
				</p>
			)}
		</div>
	)
}

function showEpilogue(title, slides, video) {
	if (!slides && !video) return null
	return (
		<div>
			<h2>Epilogue</h2>
			{showVideo(title, video)}
			{showSlides(slides)}
		</div>
	)
}

function showVideo(title, video) {
	if (!video) return null

	return (
		<React.Fragment>
			<p>
				Watch the video <Link to={video}>on YouTube</Link> (and don't forget to like and
				sub... ah, you know the drill).
			</p>
			<div className={style.video}>
				<Video title={title} video={video} />
			</div>
		</React.Fragment>
	)
}

function showSlides(slides) {
	if (!slides) return null

	if (slides.url)
		return (
			<p>
				You can <Link to={slides.url}>browse the slides</Link>.
			</p>
		)

	if (slides.pdf)
		return (
			<p>
				You can <a href={`/slides/${slides.pdf}`}>browse the slides (PDF)</a> or{" "}
				<a href={`/slides/${slides.pdf}`} download>
					download them
				</a>
				.
			</p>
		)
}

function speakerList(speakers) {
	if (!speakers || speakers.length === 0) return

	if (speakers.length === 1)
		return (
			<div>
				<h2>Speaker</h2>
				{speaker(speakers[0])}
			</div>
		)

	return (
		<div>
			<h2>Speakers</h2>
			{speakers.map(speaker)}
		</div>
	)
}

function speaker(speaker) {
	return (
		<div className={style.speaker}>
			<SpeakerCard
				speakerSlug={speaker.frontmatter.slug}
				size="small"
				linkImage
				className={style.speakerImage}
			/>
			<div className={style.text}>
				<h3 className={style.speakerName}>
					<SpeakerName speaker={speaker} linkName showSocial />
				</h3>
				<p className={style.speakerBio}>
					{speaker.frontmatter.bio} <Link to={speaker.fields.path}>More...</Link>
				</p>
			</div>
		</div>
	)
}

// AFTER: add `video` and `slides { pdf url }` to `frontmatter`
export const eventFragment = graphql`
	fragment Event on MarkdownRemark {
		htmlAst
		fields {
			path
			collection
		}
		frontmatter {
			title
			slug
			canceled
			excerpt
			language
			track
			card
			speakers {
				fields {
					path
				}
				frontmatter {
					name
					slug
					card
					bio
					...Social
				}
			}
		}
	}
`

export default Event
